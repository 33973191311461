<script>
  import { Router } from '@sveltech/routify';

  import { routes } from '../.routify/routes';
  import { websocket } from './stores';

  import { SvelteToast } from '@zerodevx/svelte-toast';

  $: websocket.connectWebsocket();
</script>

<Router {routes} />
<SvelteToast />

<style global lang="scss">
  @import '../static/global.css';
  @import '../static/styles/index.scss';
</style>
