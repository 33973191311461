export const key = 'MINERIUM_';

export const getStoredValue = key => {
  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${key}=`));

  if (!cookie) {
    return null;
  }

  return cookie.split('=')[1];
};

export const storeValue = (key, value, expireDays = 3650) => {
  document.cookie = `${key}=${value};domain=${
    process.env.HOST
  };path=/;samesite;secure;expires=${new Date(
    Date.now() + 1000 * 60 * 60 * 24 * expireDays,
  ).toGMTString()}`;
};
