import { writable } from 'svelte/store';

import { key, storeValue } from './_util';
import { safeLocalStorage } from '../utils/window';
import websocket from './websocket';

const initialData = null;

const createUserStore = () => {
  const { subscribe, set } = writable(initialData);
  const data = safeLocalStorage().getItem(key + 'user');
  if (data) {
    try {
      set(JSON.parse(data));
    } catch (err) {
      safeLocalStorage().removeItem(key + 'user');
    }
  }
  null;

  subscribe(data => {
    safeLocalStorage().setItem(key + 'user', JSON.stringify(data));
    storeValue(key + 'LOGGED_IN', data !== null);
    if (data && data.token) {
      websocket.login(data.token);
    } else {
      websocket.close();
    }
  });

  return {
    subscribe,
    set,
    reset: () => set(initialData),
  };
};

export default createUserStore();
