export const safeLocalStorage = () => {
  if (typeof process === 'undefined') {
    return window.localStorage;
  }

  return {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
  };
};
