import { writable } from 'svelte/store';

const createWebsocketStore = () => {
  const { set, update, subscribe } = writable({
    websocket: {
      error: null,
      connected: false,
    },
    pool: {},
    dashboard: {},
  });

  let ws,
    queued = [],
    timeout = 1000;

  const connectWebsocket = () => {
    const isJsDom =
      navigator.userAgent.includes('Node.js') ||
      navigator.userAgent.includes('jsdom');
    if (isJsDom) {
      return;
    }

    timeout = timeout < 30000 ? timeout + timeout : 30000;

    try {
      ws = new WebSocket(process.env.APP_WEBSOCKET_URL);
    } catch (err) {
      setTimeout(connectWebsocket, timeout);
      return;
    }

    ws.onopen = () => {
      update(data => {
        data.websocket.connected = true;
        data.websocket.error = null;
        return data;
      });

      resumeQueue();
      timeout = 1000;
    };

    ws.onclose = () => {
      update(data => {
        data.websocket.connected = false;
        data.websocket.error = null;
        return data;
      });

      ws = null;
      setTimeout(connectWebsocket, timeout);
    };

    ws.onmessage = evt => {
      try {
        handleMessage(JSON.parse(evt.data));
      } catch (err) {
        console.log(evt.data);
      }
    };

    ws.onerror = evt => {
      update(data => {
        data.websocket.error = evt.data;
        return data;
      });
    };
  };

  const queue = ({ type, data, coin, extra }) => {
    if (ws && ws.readyState === ws.OPEN) {
      sendMessage({ type, data, coin, extra });
      return;
    }

    queued.push({ type, data, coin, extra });
  };

  const resumeQueue = () => {
    while (queued.length !== 0) {
      if (!sendMessage(queued.shift())) {
        return;
      }
    }
  };

  const sendMessage = ({ type, data, coin, extra }) => {
    if (!ws || ws.readyState !== ws.OPEN) {
      queue({ type, data, coin, extra });
      return false;
    }

    ws.send(
      JSON.stringify({
        type,
        data,
        coin,
        extra,
      }),
    );
    return true;
  };

  const handleMessage = ({ type, data, loading = false }) => {
    switch (type) {
      case 'dashboard_hour':
      case 'dashboard_day':
        update(store => {
          if (loading) {
            store.dashboard.loading = true;
            return store;
          }

          store.dashboard = data;
          return store;
        });
        break;

      case 'pool_hour_all':
      case 'pool_hour_btc':
      case 'pool_hour_bch':
      case 'pool_hour_bsv':
      case 'pool_hour_dgb':
      case 'pool_day_all':
      case 'pool_day_btc':
      case 'pool_day_bch':
      case 'pool_day_bsv':
      case 'pool_day_dgb':
        update(store => {
          if (loading) {
            store.pool.loading = true;
            return store;
          }

          store.pool = data;
          return store;
        });
        break;
    }
  };

  const listen = (category, coin) => {
    if (category === 'pool_hour' || category === 'pool_day') {
      sendMessage({ type: 'subscribe_public', data: category, coin });
      handleMessage({ type: `${category}_${coin}`, loading: true });
      return;
    }

    sendMessage({ type: 'subscribe', data: category });
    handleMessage({ type: category, loading: true });
  };

  const stopListening = (category, coin) => {
    const key = `${category}_${coin}`;

    if (category === 'pool_hour' || category === 'pool_day') {
      sendMessage({ type: 'unsubscribe_public', data: category, coin });
      return;
    }

    sendMessage({ type: 'unsubscribe', data: category });
  };

  return {
    update,
    subscribe,
    connectWebsocket,
    close: () => {
      if (ws) {
        ws.close();
      }
    },
    login: token => sendMessage({ type: 'authenticate', data: token }),
    listen,
    stopListening,
  };
};

const websocket = createWebsocketStore();

export default websocket;
